<template>
  <div class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/salesin')">Выгрузка чеков</h3>
      <h3 class="pointer" @click="followLink('/chesstable')">Расписание с чеками</h3>
      <h3 class="pointer">Настройка</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
